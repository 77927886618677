import * as THREE from "three";

export function moneyFormat (value) {
  const formtatter = Intl.NumberFormat('ru', {
    style: 'decimal',
    // currency: 'RUB',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  return formtatter.format(value)
  // return  (Math.round((value + Number.EPSILON) * 100) / 100).toString().replace('.', ',')
}

export function dateFormat (value, format) {
  return new Intl.DateTimeFormat('ru-RU').format(new Date(value))
}

export function isoDate (date = null) {
  if (!date) {
    date = new Date()
  }
  return date.toISOString().split('T')[0]
}

export function arrToObjBy (arr, key) {
  return arr.reduce((r, x) => {
    r[x[key]] = x
    return r
  }, {})
}


export function russianStringToFloat(string) {
  return string.toString().replace(",", ".");
}

/******************************************************* Math *********************************************************/


export function calculateDistanceBetweenPointAndLine(point, lineStart, lineEnd) {
  const diff1 = new THREE.Vector3(0, 0, 0).subVectors(point, lineStart);
  const diff2 = new THREE.Vector3(0, 0, 0).subVectors(point, lineEnd);
  const cross = new THREE.Vector3(0, 0, 0).crossVectors(diff1, diff2);
  const diff3 = new THREE.Vector3(0, 0, 0).subVectors(lineEnd, lineStart);
  return cross.length() / diff3.length();
}
